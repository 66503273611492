import React from 'react';

const Share = () => {
  return (
    <div className="share-container">
      <h1>Thank you for joining the waitlist!</h1>
      <p>Share Collaborative Catering with your friends:</p>
      <ul>
        <li><a href="https://www.facebook.com/sharer/sharer.php?u=https://www.collaborativecatering.com">Share on Facebook</a></li>
        <li><a href="https://twitter.com/intent/tweet?text=Join%20Collaborative%20Catering%20Waitlist!%20https://www.collaborativecatering.com">Share on Twitter</a></li>
        <li><a href="https://www.linkedin.com/shareArticle?mini=true&url=https://www.collaborativecatering.com">Share on LinkedIn</a></li>
      </ul>
    </div>
  );
};

export default Share;
