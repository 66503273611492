import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth0();

  console.log('ProtectedRoute: isLoading:', isLoading, 'isAuthenticated:', isAuthenticated);

  // If loading, show a loading indicator
  if (isLoading) {
    return <div>Loading...</div>;
  }

  // If the user is not authenticated, redirect to the home page
  if (!isAuthenticated) {
    console.log('User is not authenticated, redirecting to home page.');
    return <Navigate to="/" />;
  }

  // If authenticated, render the child component
  console.log('User is authenticated, rendering children.');
  return children;
};

export default ProtectedRoute;
