import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const SignUpButton = () => {
  const { loginWithRedirect } = useAuth0();

  const handleSignUp = () => {
    loginWithRedirect({
      screen_hint: 'signup', // This directs Auth0 to show the signup page
    });
  };

  return <button onClick={handleSignUp}>Sign Up</button>;
};

export default SignUpButton;
