import React from 'react';
import { createRoot } from 'react-dom/client'; // Correct import for React 18
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom'; // Import Router
import Auth0ProviderWithHistory from './auth0-provider'; // Your Auth0 provider

console.log('App is starting...');

const container = document.getElementById('root');
const root = createRoot(container); // Creates a root

root.render(
  <React.StrictMode>
    <Router> {/* Single Router wrapping the Auth0 provider */}
      <Auth0ProviderWithHistory>
        <App />
      </Auth0ProviderWithHistory>
    </Router>
  </React.StrictMode>
);
reportWebVitals();
