import React, { useEffect } from 'react';

/* global Paddle */

const Pay = () => {
    useEffect(() => {
        const loadPaddle = () => {
            const script = document.createElement('script');
            script.src = "https://cdn.paddle.com/paddle/paddle.js";
            script.onload = () => {
                console.log('Paddle script loaded');
                Paddle.Setup({ vendor: 23059 });

                // Ensure the DOM is fully loaded before opening the checkout
                window.addEventListener('load', () => {
                    const transactionId = new URLSearchParams(window.location.search).get('_ptxn');
                    if (transactionId) {
                        const checkoutContainer = document.getElementById('checkout-container');
                        if (checkoutContainer) {
                            Paddle.Checkout.open({
                                transaction: transactionId,
                                method: 'inline', // Can also use 'overlay' for popup style
                                frameTarget: 'checkout-container', // Target div for inline checkout
                            });
                        } else {
                            console.error('Checkout container not found');
                        }
                    } else {
                        console.warn('No transaction ID found in URL');
                    }
                });
            };
            document.body.appendChild(script);
        };

        loadPaddle();
    }, []);

    return (
        <div>
            <h1>Payment Page</h1>
            <div id="checkout-container"></div>  {/* Ensure this container is rendered */}
        </div>
    );
};

export default Pay;
