import React from 'react';
import { useNavigate } from 'react-router-dom';
import FoodForm from './FoodForm'; // Assuming this exists
import './App.css'; // Use App.css for styling

const Home = () => {
  const navigate = useNavigate();

  // Handle redirect to the waitlist page
  const handleReserveSpotClick = () => {
    navigate('/waitlist');
  };

  return (
    <div className="">
      <header className="">
        {/* <img src="logo.png" alt="Collaborative Catering Logo" className="logo" /> */}
        <h1>Collaborative Catering</h1>
        <p>Smart Catering Form On Your Website</p>
      </header>

      <section className="food-form-section">
        <h2>Your Custom Catering Form</h2>
        <FoodForm 
          defaultConfig={{
            primaryColor: '#3498DB',
            secondaryColor: '#AEDFF7',
            contrastColor: '#ea9b47',
            backgroundColor: '#FFFFFF',
            fontPrimary: 'Open Sans',
          }}
        />
      </section>

      <footer className="waitlist-footer">
        <button className="reserve-spot-button" onClick={handleReserveSpotClick}>
          Reserve Spot
        </button>
      </footer>
    </div>
  );
};

export default Home;
